import React, { useState } from "react";
import { Form } from "react-bootstrap";

const UpdateVariant = ({
  name,
  options,
  updateHandler,
  deleteHandler,
  showBox,
}) => {
  const [value, setValue] = useState(name);
  const [valueError, setValueError] = useState("");
  const [delValue, setDelValue] = useState("");
  const [delError, setDelError] = useState("");

  const handleValueChange = (e) => {
    setValue(e.target.value);
    if (
      e.target.value !== name &&
      options.find(
        (entry) => entry.name.toLowerCase() === e.target.value.toLowerCase()
      )
    ) {
      setValueError("Already exists!");
    } else {
      setValueError("");
    }
  };

  const handleUpdate = () => {
    let err = false;
    if (!value.trim() || !value) {
      setValueError("Required");
      err = true;
    }
    if (!err) {
      if (!valueError && value !== name) {
        updateHandler(name, value);
        showBox(false);
      } else if (!valueError) {
        showBox(false);
      }
    }
  };

  const handleDelete = () => {
    if (delValue === name) {
      deleteHandler(name);
      showBox(false);
    }
  };

  const handleUpdateEnter = (e) => {
    if (e.key === "Enter") {
      handleUpdate();
    }
  };

  const handleDeleteEnter = (e) => {
    if (e.key === "Enter") {
      handleDelete();
    }
  };

  return (
    <div className="variants-popup">
      <div className="variants-popup_overlay" onClick={() => showBox(false)} />
      <div className="variants-update">
        <div className="variants-update_header">
          <span>Edit variant</span>
          <button onClick={() => showBox(false)}>
            <i className="fa fa-close"></i>
          </button>
        </div>
        <div className="variants-update_main">
          <div>
            <div className="mb-3">
              <span className="variants-update_label">Name</span>
              <Form.Control
                type="text"
                placeholder="Eg: Color"
                value={value}
                onChange={handleValueChange}
                onKeyDown={handleUpdateEnter}
              />
              {valueError ? <span>{valueError}</span> : null}
            </div>
            <button
              type="button"
              className="bton bton--sm bton--full bton--primary"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
          <div className="variants-update_delete">
            <div className="flex-col">
              <span className="delete_label">Delete variant</span>
              <span className="delete_sub">
                Type <span>"{name}"</span> to confirm
              </span>
            </div>
            <Form.Control
              type="text"
              placeholder="Retype variant name"
              value={delValue}
              onChange={(e) => {
                setDelValue(e.target.value);
                if (e.target.value !== name) {
                  setDelError("Does not match");
                } else {
                  setDelError("");
                }
              }}
              onKeyDown={handleDeleteEnter}
            />
            {delError ? <span>{delError}</span> : null}
            <button
              type="button"
              onClick={handleDelete}
              className="mt-3 bton bton--sm bton--full bton--ghost--danger"
            >
              Confirm delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariant;
