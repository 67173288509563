import React, { useState } from "react";
import DeleteSubVariant from "./DeleteSubVariant";
import UpdateSubVariant from "./UpdateSubVariant";

const SubVariantItem = ({
  type,
  name,
  values,
  updateSubVariantHandler,
  deleteSubVariantHandler,
}) => {
  const [showUpdateBox, setShowUpdateBox] = useState(false);
  const [showDeleteBox, setShowDeleteBox] = useState(false);

  return (
    <div className="variant-list_item">
      <span className="variant-list_item-varname">{name}</span>
      <div className="variant-list_item-icons">
        <button
          type="button"
          onClick={() => setShowUpdateBox(true)}
          className="edit"
        >
          <i className="fa fa-edit edit"></i>
        </button>
        <button
          type="button"
          onClick={() => setShowDeleteBox(true)}
          className="dlt"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      {showUpdateBox ? (
        <UpdateSubVariant
          type={type}
          oldValue={name}
          values={values}
          updateHandler={updateSubVariantHandler}
          showBox={setShowUpdateBox}
        />
      ) : null}
      {showDeleteBox ? (
        <DeleteSubVariant
          type={type}
          name={name}
          showBox={setShowDeleteBox}
          deleteHandler={deleteSubVariantHandler}
        />
      ) : null}
    </div>
  );
};

export default SubVariantItem;
