import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./productsSlice";
import categoriesReducer from "./categorySlice";
import carouselReducer from "./carouselSilce";
import userReducer from "./userSlice";
import orderReducer from "./orderSlice";
import promoReducer from "./promoSlice";
import notificationReducer from "./notificationSlice";
import reviewReducer from "./reviewSlice";

export default configureStore({
  reducer: {
    products: productsReducer,
    categories: categoriesReducer,
    carousels: carouselReducer,
    users: userReducer,
    orders: orderReducer,
    promos: promoReducer,
    notification: notificationReducer,
    review: reviewReducer,
  },
});
