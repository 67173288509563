import React, { useState } from "react";
import VariantProductItem from "./VariantProductItem";

const VariantProducts = ({ variants, setVariants, type1, type2, images }) => {
  const [uploaded, setUploaded] = useState([]);
  const handleChange = ({ subType1, subType2, stock, price }) => {
    if (subType2) {
      setVariants((prev) =>
        prev.map((entry) =>
          entry[type1] === subType1 && entry[type2] === subType2
            ? { ...entry, stock, price }
            : entry
        )
      );
    } else {
      setVariants((prev) =>
        prev.map((entry) =>
          entry[type1] === subType1 ? { ...entry, stock, price } : entry
        )
      );
    }
  };

  const imageHandler = ({ subType1, subType2, images }) => {
    if (subType2) {
      setVariants((prev) =>
        prev.map((entry) =>
          entry[type1] === subType1 && entry[type2] === subType2
            ? { ...entry, images: images }
            : entry
        )
      );
    } else {
      setVariants((prev) =>
        prev.map((entry) =>
          entry[type1] === subType1 ? { ...entry, images: images } : entry
        )
      );
    }
  };
  return (
    <div className="mt-4 variant-products">
      <h2 className="">Variants Products</h2>
      <table className="variant-products_table">
        <thead className="variant-products_table_head">
          <tr className="variant-products_table_row">
            <th className="variant-products_table_th">Image</th>
            <th className="variant-products_table_th">Name</th>
            <th className="variant-products_table_th">Stock</th>
            <th className="variant-products_table_th">Price</th>
          </tr>
        </thead>
        <tbody className="variant-products_table_body">
          {variants && variants.length > 0 ? (
            variants.map((entry) => (
              <VariantProductItem
                key={entry[type1] + entry[type2]}
                data={entry}
                handleChange={handleChange}
                type1={type1}
                type2={type2}
                images={images}
                uploaded={uploaded}
                setUploaded={setUploaded}
                imageHandler={imageHandler}
              />
            ))
          ) : (
            <tr className="variant-products_table_row">
              <td colSpan={4} className="text-center">
                No variants added
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VariantProducts;
