import axios from "axios";
import React, { useState } from "react";

const UploadVariantImage = ({
  showBox,
  selectedImages,
  images,
  uploaded,
  setUploaded,
  imageHandler,
  subType1,
  subType2,
}) => {
  const [selected, setSelected] = useState(selectedImages);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);

  const handleUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    setError(false);
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);
      setUploaded((prev) => [data, ...prev]);
    } catch (error) {
      setError(true);
    } finally {
      setUploading(false);
    }
  };

  const toggleSelect = (imageName) => {
    const exists = selected.includes(imageName);

    if (exists) {
      setSelected((prev) => prev.filter((entry) => entry !== imageName));
    } else {
      setSelected((prev) => [imageName, ...prev]);
    }
  };

  const handleDone = () => {
    imageHandler({ subType1, subType2, images: selected });
    showBox(false);
  };

  return (
    <div className="variants-popup">
      <div className="variants-popup_overlay" onClick={() => showBox(false)} />
      <div className="variants-upload">
        <div className="variants-upload_header">
          <span>Upload or Choose Existing</span>
          <button onClick={() => showBox(false)}>
            <i className="fa fa-close"></i>
          </button>
        </div>
        {error ? <span>Some error occured. Please try again</span> : null}
        <div className="variants-upload_main">
          <label className="variants-upload_btn">
            <span>Add Image</span>
            <input
              type="file"
              name="image"
              style={{ display: "none" }}
              accept="image/png, image/jpg, image/jpeg"
              onChange={handleUpload}
            />
          </label>
          {uploading ? <span>Please Wait</span> : null}
          {uploaded.map((entry) => (
            <button
              type="button"
              onClick={() => toggleSelect(entry)}
              key={entry}
              className={`variants-upload_img ${
                selected.includes(entry) ? "selected" : null
              }`}
            >
              <img
                src={`${process.env.REACT_APP_IMAGE_PREFIX}${entry}`}
                alt=""
              />
            </button>
          ))}
          {images.map((entry) => (
            <button
              type="button"
              onClick={() => toggleSelect(entry)}
              className={`variants-upload_img ${
                selected.includes(entry) ? "selected" : null
              }`}
              key={entry}
            >
              <img
                src={`${process.env.REACT_APP_IMAGE_PREFIX}${entry}`}
                alt=""
              />
            </button>
          ))}
        </div>
        <div className="variants-upload_btns">
          <button
            type="button"
            className="bton bton--sm bton--nacked"
            onClick={() => showBox(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bton bton--sm bton--primary"
            onClick={handleDone}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadVariantImage;
