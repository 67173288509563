import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  isLoading: null,
  isHistoryLoading: null,
  orders: null,
  ordersHistory: null,
  isError: null,
  message: null,
};

export const getOrderListAsync = createAsyncThunk(
  'user/getOrderListAsync',
  async (data, thunkApi) => {
    try {
      const { token } = thunkApi.getState().users.user;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `/api/orders?pageNumber=${data?.pageNumber}`,
        config
      );
      const { orders, pages, page } = response.data && response.data;
      return { orders, pages, page };
    } catch (error) {
      console.error(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const filterGuestAsync = createAsyncThunk(
  'user/getOrderListAsync',
  async (data, thunkApi) => {
    try {
      const { token } = thunkApi.getState().users.user;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `/api/orders/orders-guest?pageNumber=${data.pageNumber}`,
        config
      );
      const { orders, pages, page } = response.data && response.data;
      return { orders, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const filterWithStatusAsync = createAsyncThunk(
  'user/getOrderListAsync',
  async (data, thunkApi) => {
    try {
      const { token } = thunkApi.getState().users.user;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `/api/orders/list/${data.status}?pageNumber=${data.pageNumber}`,
        config
      );
      const { orders, pages, page } = response.data && response.data;
      return { orders, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const getOrderHistoryAsync = createAsyncThunk(
  'user/getOrderHistoryAsync',
  async (data, thunkApi) => {
    try {
      const { token } = thunkApi.getState().users.user;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `/api/orders/history?pageNumber=${data.pageNumber}`,
        config
      );
      const { orders, pages, page } = response.data && response.data;
      return { orders, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const getOrderHistoryFilterAsync = createAsyncThunk(
  'user/getOrderHistoryFilterAsync',
  async (data, thunkApi) => {
    try {
      const { token } = thunkApi.getState().users.user;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `/api/orders/history/filter?pageNumber=${data.pageNumber}`,
        { status: data.filterState, date: data.filterStateDate },
        config
      );
      const { orders, pages, page } = response.data && response.data;
      return { orders, pages, page };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    filterOrderHistory: (state, action) => {
      state.orderHistoryFiltered = state.ordersHistory.filter(
        (i) => i.deliveryStatus === action.payload
      );
    },
    filterOrderHistoryWithDate: (state, action) => {
      state.orderHistoryFiltered = action.payload;
    },
  },
  extraReducers: {
    [getOrderListAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getOrderListAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orders = action.payload.orders;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    [filterGuestAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [filterGuestAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orders = action.payload.orders;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    [filterWithStatusAsync.pending]: (state, action) => {
      state.isLoading = true;
    },
    [filterWithStatusAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.orders = action.payload.orders;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
    },
    [getOrderListAsync.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.orders = null;
    },
    [getOrderHistoryAsync.pending]: (state, action) => {
      state.isHistoryLoading = true;
    },
    [getOrderHistoryAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ordersHistory = action.payload.orders;
      state.pagesHistory = action.payload.pages;
      state.pageHistory = action.payload.page;
    },
    [getOrderHistoryAsync.rejected]: (state, action) => {
      state.isHistoryLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.orders = null;
    },
    [getOrderHistoryFilterAsync.pending]: (state, action) => {
      state.isHistoryLoading = true;
    },
    [getOrderHistoryFilterAsync.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ordersHistory = action.payload.orders;
      state.pagesHistory = action.payload.pages;
      state.pageHistory = action.payload.page;
    },
    [getOrderHistoryFilterAsync.rejected]: (state, action) => {
      state.isHistoryLoading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.orders = null;
    },
  },
});

export const { filterOrderHistory, filterOrderHistoryWithDate } =
  orderSlice.actions;
export default orderSlice.reducer;
