import React, { useState } from "react";
import { Form } from "react-bootstrap";

const AddVarient = ({ onAdd }) => {
  const [value, setValue] = useState("");
  const handleAdd = () => {
    onAdd(value);
    setValue("");
  };

  const handleAddEnter = (e) => {
    if (e.key === "Enter") {
      handleAdd();
    }
  };

  return (
    <div className="flex-between">
      <Form.Control
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleAddEnter}
        placeholder="Eg: Color"
      />
      <button
        type="button"
        className="bton bton--sm bton--primary"
        onClick={handleAdd}
      >
        Add
      </button>
    </div>
  );
};

export default AddVarient;
