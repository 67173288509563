import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  pendingReviewsData: null,
  pendingReviewsPages: 0,
  pendingReviewsCount: 0,

  fetchReviewLoading: false,
  fetchReviewError: "",
};

export const fetchPendingReviews = createAsyncThunk(
  "review/fetchPendingReviews",
  async (pageNumber, thunkApi) => {
    try {
      const { user } = thunkApi.getState().users;
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const response = await axios.get(
        `/api/reviews?status=PENDING&page=${pageNumber}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const approveReviewAsync = createAsyncThunk(
  "review/approveReviewAsync",
  async (id, thunkApi) => {
    try {
      const { user } = thunkApi.getState().users;
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const response = await axios.patch(
        `/api/reviews/${id}/accept`,
        {},
        config
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const rejectReviewAsync = createAsyncThunk(
  "review/rejectReviewAsync",
  async ({ reason, id }, thunkApi) => {
    try {
      const { user } = thunkApi.getState().users;
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const response = await axios.patch(
        `/api/reviews/${id}/reject`,
        { remarks: reason },
        config
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Fetch review
      .addCase(fetchPendingReviews.pending, (state) => {
        state.fetchReviewError = "";
        state.fetchReviewLoading = true;
      })
      .addCase(fetchPendingReviews.fulfilled, (state, action) => {
        state.pendingReviewsData = action.payload.data;
        state.pendingReviewsPages = action.payload.pages;
        state.pendingReviewsCount = action.payload.count;
        state.fetchReviewLoading = false;
        state.fetchReviewError = "";
      })
      .addCase(fetchPendingReviews.rejected, (state, action) => {
        state.fetchReviewLoading = false;
        state.fetchReviewError = action.payload.message;
      })

      //Fetch review History
      .addCase(approveReviewAsync.fulfilled, (state, action) => {
        state.pendingReviewsData = state.pendingReviewsData.filter(
          (entry) => entry._id !== action.payload
        );
      })

      // // Reject Review
      .addCase(rejectReviewAsync.fulfilled, (state, action) => {
        state.pendingReviewsData = state.pendingReviewsData.filter(
          (entry) => entry._id !== action.payload
        );
      });
  },
});

export const getPendingReview = (state) => state.review.pendingReviewsData;
export const getPendingPages = (state) => state.review.pendingReviewsPages;
export const getPendingCount = (state) => state.review.pendingReviewsCount;

export const getFetchPendingReviewLoading = (state) =>
  state.review.fetchReviewLoading;
export const getFetchPendingReviewError = (state) =>
  state.review.fetchReviewError;

export default reviewSlice.reducer;
