import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function OrderStatus() {
  const navigate = useNavigate();
  const [counts, setCounts] = useState(null);
  const { user } = useSelector((state) => state.users);

  const getOverview = async () => {
    const response = await axios.get('/api/orders/overview', {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    if (response.status === 200) {
      setCounts(response.data);
    }
  };

  useEffect(() => {
    if (user) {
      getOverview();
    } else {
      navigate('/login');
    }
  }, [user]);

  return (
    <section>
      <Container>
        <Row>
          <h1>ORDER STATUS</h1>
          <div className='text-muted'>your overall order status</div>
        </Row>

        <Row>
          <Col md={3}>
            <section className='statusCard bprt '>
              <div className='statusCard-status  cpr'>Placed</div>
              <div className='statusCard-number  cpr'>{counts?.placed}</div>
            </section>
          </Col>
          <Col md={3}>
            <section className='statusCard bprt bwat'>
              <div className='statusCard-status  cwa'>Processing</div>
              <div className='statusCard-number  cwa'>{counts?.processing}</div>
            </section>
          </Col>
          <Col md={3}>
            <section className='statusCard bsut '>
              <div className='statusCard-status  csu'>Dispatched</div>
              <div className='statusCard-number  csu'>{counts?.dispatched}</div>
            </section>
          </Col>
          <Col md={3}>
            <section className='statusCard bset '>
              <div className='statusCard-status  cse'>On the Way</div>
              <div className='statusCard-number  cse'>{counts?.onTheWay}</div>
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default OrderStatus;
