import { Dropdown } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { logout } from '../../redux/userSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getNotificationsAsync } from '../../redux/notificationSlice.js';
import LoaderComp from '../LoaderComp/index.js';

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const { notifications, loading } = useSelector((state) => state.notification);
  function handleLogout() {
    dispatch(logout());
  }
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user]);

  useEffect(() => {
    dispatch(getNotificationsAsync);
  }, []);
  return (
    <section className='navBar'>
      <ul className='navBox'>
        {/* <li className='navBox-items'>
          {' '}
          <span className='adminName cpntr' onClick={() => handleLogout()}>
            {' '}
            LogOut
          </span>
        </li> */}
        <li className='navBox-items'>
          {' '}
          <i className='fa  fa-user-circle'></i>&nbsp;{' '}
          <span className='adminName'> {user && user.name} </span>
        </li>
        <li className='navBox-items'>
          <section role='notification'>
            <Dropdown>
              <Dropdown.Toggle variant='success' id='dropdown-basic'>
                <div className='navBox-items-notification'>
                  <i className='fa fa-bell-o'></i>
                  {loading ? (
                    <LoaderComp />
                  ) : (
                    notifications &&
                    notifications.length > 0 && (
                      <div className='notificationCounter'>
                        {notifications && notifications.length}
                      </div>
                    )
                  )}
                </div>{' '}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <section className='notificationWrapper'>
                  <h2>Notification</h2>

                  <hr />

                  <ul className='notificationList'>
                    <div className='notificationList-day'>clear</div>
                    {notifications && notifications.length > 0 ? (
                      <li>
                        <section className='notificationList-text'>
                          You received an order from
                          <strong> aroj Kunwar</strong>
                        </section>
                        <div className='notificationList-time'>2 min ago</div>
                      </li>
                    ) : (
                      <span>Empty</span>
                    )}
                  </ul>
                </section>
              </Dropdown.Menu>
            </Dropdown>
          </section>
        </li>
      </ul>
    </section>
  );
};

export default Index;
