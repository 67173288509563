import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OrderStatus from '../../components/OrderStatus';
import { getOrderListAsync } from '../../redux/orderSlice';
import DemandSupplyChart from '../../components/DemandSupplyChart';
import LoaderComp from '../../components/LoaderComp';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const { isLoading, orders } = useSelector((state) => state.orders);
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, []);
  if (isLoading) return <LoaderComp />;
  return (
    <>
      <section className='dashboardWrapper'>
        <h1>Current order status</h1>

        {<OrderStatus />}
      </section>
      <section className='salesWrapper'>
        <h1>Sales Details</h1>
        <DemandSupplyChart />
      </section>
    </>
  );
};

export default Dashboard;
