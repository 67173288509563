import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderComp from '../../components/LoaderComp';
import Paginate from '../../components/Paginate';

const Enquiries = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [enquiries, setEnquiries] = useState(null);
  const { user } = useSelector((state) => state.users);
  const getEnquiryList = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get(
      `/api/notification/getenquiry?pageNumber=${pageNumber}`,
      config
    );
    if (response.status === 200) {
      setEnquiries(response.data);

      setLoading(false);
    }
  };

  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  useEffect(() => {
    getEnquiryList({ pageNumber });
  }, [pageNumber]);
  if (loading) return <LoaderComp />;
  return (
    <>
      <Container>
        <section>
          <h1>Enquiries</h1>
        </section>

        <section className='transactionWrapper'>
          {/* <section className='transFilter'>
            <Form.Select aria-label='Default select example'>
              <option>Sort By</option>
              <option value='1'>Orders low to high</option>
              <option value='2'>Orders high to low</option>
              <option value='3'>ascending</option>
              <option value='3'>decending</option>
            </Form.Select>
          </section> */}

          <section className='transTable'>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th width='15%'>Name</th>
                  <th width='15%'>Email</th>
                  <th width='15%'>Phone</th>
                  <th width='15%'>Date</th>
                  <th width='40%'>Message</th>
                </tr>
              </thead>
              <tbody>
                {enquiries?.enquiry.map((i) => (
                  <tr key={i._id}>
                    <td>{i.fullname}</td>
                    <td>{i.email}</td>
                    <td>{i.phone}</td>
                    <td>{i.createdAt.split('T')[0]}</td>
                    <td>{i.message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {!loading && (
              <Paginate
                pages={enquiries.pages}
                page={enquiries.page}
                isAdmin={true}
                list='enquiries'
                navigate={navigate}
              />
            )}
          </section>
        </section>
      </Container>
    </>
  );
};

export default Enquiries;
