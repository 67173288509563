/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  ListGroup,
  CloseButton,
  Popover,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import LoaderComp from "../../components/LoaderComp";
import SearchProductsDropdown from "../../components/SearchProductsDropdown";
import ReactQuill from "react-quill";

import { getCategoriesAsync } from "../../redux/categorySlice";
import {
  deleteProductAsync,
  getProductDetailsAsync,
  reset,
  updateProductAsync,
} from "../../redux/productsSlice";
import Variant from "../../components/Variant";

const EditProduct = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.category);
  const product = useSelector((state) => state.products.product);
  const loading = useSelector((state) => state.products.detailsLoading);
  const { deleteSuccess } = useSelector((state) => state.products);
  const updateSuccess = useSelector((state) => state.products.updateSuccess);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getProductDetailsAsync(id));
    }
  }, [dispatch, id]);

  const [heroImg, setHeroImg] = useState("");
  let [galleryImage, setGalleryImage] = useState([]);
  const [allImages, setAllImages] = useState([]);

  const [show, setShow] = useState(false);

  const [formField, setFormField] = useState({
    name: "",
    source: "",
    orderLimit: 0,
    isFeatured: false,
    newArrival: false,
    category: "",
    subCategory: "",
    subCategory2: "",
    price: "",
    discount: "",
    heroImage: "",
    stock: "",
    information: [],
    gallery: [],
    description: "",
    toDisplay: false,
    seoDescription: "",
    keywords: [],
    related: [],
  });
  const [products, setProducts] = useState([]);
  let [productList, setProductList] = useState([]);
  const [description, setDescription] = useState("");

  const [variants, setVariants] = useState([]);
  const [options, setOptions] = useState([]);
  const [variantsError, setVariantsError] = useState("");
  const [optionsError, setOptionsError] = useState("");

  useEffect(() => {
    if (!loading && product !== null) {
      setFormField({
        name: product.name,
        source: product.source,
        orderLimit: product.orderLimit,
        isFeatured: product.isFeatured,
        category: product.category._id,
        subCategory: product.subCategory,
        subCategory2: product.subCategory2,
        price: product.price,
        discount: product.discount,
        heroImage: product.heroImage,
        stock: product.stock,
        information: product.information,
        // description: product.description,
        toDisplay: product.toDisplay,
        newArrival: product.newArrival,
        seoDescription: product.seoDescription,
        keywords: product.keywords,
        gallery: product.gallery,
        related: product.related,
      });
      setDescription(product.description);

      setProductList(product.related);

      setCategoryName(product.category.name);

      setHeroImg(product.heroImage);

      setGalleryImage(product.gallery);

      setSubCateogryList(product.category.subCategory);

      product.category.subCategory[0] &&
        setSubCateogry2List(product.category.subCategory[0].subCategory2);
      setSubCategoryName(product.subCategory);
      setSubCategory2Name(product.subCategory2);

      setVariants(product.variants);
      setOptions(product.options);

      const tempImg = [];

      for (const e of product.variants) {
        for (const i of e.images) {
          if (i !== product.heroImage && !product.gallery.includes(i)) {
            tempImg.push(i);
          }
        }
      }
      setAllImages(tempImg);
    }
  }, [loading, product]);

  const [subCategoryList, setSubCateogryList] = useState([]);
  const [subCategory2List, setSubCateogry2List] = useState([]);
  const [informationText, setInformationText] = useState("");
  const [infoDescriptionText, setinfoDescriptionText] = useState("");
  const [keyword, setKeyword] = useState("");
  const [showInfoExists, setShowInfoExists] = useState(false);
  const [showKeyExists, setShowKeyExists] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [subCateogryName, setSubCategoryName] = useState("");
  const [subCateogry2Name, setSubCategory2Name] = useState("");

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  useEffect(() => {
    const fetch = async () => {
      await axios.get("/api/products/search").then((res) => {
        setProducts(res.data);
      });
    };
    fetch();
  }, []);

  const {
    name,
    source,
    category,
    subCategory,
    subCategory2,
    orderLimit,
    heroImage,
    price,
    discount,
    stock,
    isFeatured,
    gallery,
    newArrival,
    toDisplay,
    related,
    information,
    seoDescription,
    keywords: seoKeywords,
  } = formField;

  const onChange = (e) =>
    setFormField({ ...formField, [e.target.name]: e.target.value });
  // const onWriterChange = (event, editor) => {
  //   setDescription(editor.data.get());
  // };

  const handleFeaturedChange = (e) =>
    setFormField({ ...formField, isFeatured: !formField.isFeatured });
  const handleToDisplayChange = (e) =>
    setFormField({ ...formField, toDisplay: !formField.toDisplay });
  const handleNewArrivalChange = (e) =>
    setFormField({ ...formField, newArrival: !formField.newArrival });

  const [validate, setValidate] = useState({
    name: false,
    category: false,
    price: false,
    orderLimit: false,
    stock: false,
    discount: false,
    heroImage: false,
    description: false,
  });

  const validateForm = async () => {
    var isValid = true;

    if (name.trim() === "") {
      setValidate((prev) => ({ ...prev, name: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, name: false }));
    }
    if (category.trim() === "") {
      setValidate((prev) => ({ ...prev, category: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, category: false }));
    }
    if (price.toString().trim() === "" || Number(price) < 0) {
      setValidate((prev) => ({ ...prev, price: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, price: false }));
    }
    if (discount.toString().trim() === "" || Number(discount) < 0) {
      setValidate((prev) => ({ ...prev, discount: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, discount: false }));
    }
    if (variants.length === 0) {
      if (stock.toString().trim() === "" || Number(stock) < 0) {
        setValidate((prev) => ({ ...prev, stock: true }));
        isValid = false;
      } else {
        setValidate((prev) => ({ ...prev, stock: false }));
      }
    }
    if (description.trim() === "") {
      setValidate((prev) => ({ ...prev, description: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, description: false }));
    }
    if (heroImg.trim() === "") {
      setValidate((prev) => ({ ...prev, heroImage: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, description: false }));
    }
    if (Number(orderLimit) <= 0) {
      setValidate((prev) => ({ ...prev, orderLimit: true }));
      isValid = false;
    } else {
      setValidate((prev) => ({ ...prev, orderLimit: false }));
    }

    if (options.length > 0 && variants.length === 0) {
      setVariantsError("Cannot have empty variants");
      isValid = false;
    }
    if (options.length > 0) {
      for (const entry of options) {
        if (entry.values.length === 0) {
          setOptionsError("Variant must have atleast one type");
          isValid = false;
          break;
        }
      }
    }
    if (variants.length > 0) {
      let totalStock = 0;
      let localValid = true;
      for (const entry of variants) {
        if (isNaN(entry.price)) {
          setVariantsError("Price is required");
          isValid = false;
          localValid = false;
          break;
        } else if (Number(entry.stock) < 0 || entry.price < 0) {
          setVariantsError("Stock or price cannot be negative");
          isValid = false;
          localValid = false;
          break;
        } else if (entry.price === 0) {
          setVariantsError("Price can not be zero!");
          isValid = false;
          localValid = false;
          break;
        }
        totalStock += isNaN(entry.stock) ? 0 : entry.stock;
      }
      if (localValid && totalStock <= 0) {
        setVariantsError("At least one variant must have stock!");
        isValid = false;
      }
    }

    return isValid;
  };

  const submitForm = async (e) => {
    e.preventDefault(e);
    const isValid = await validateForm();

    if (isValid) {
      productList = productList.map((e) => e._id);
      let stock = formField.stock;

      if (variants.length > 0) {
        let temp = 0;
        for (const entry of variants) {
          temp += entry.stock;
        }
        stock = temp;
      }
      const data = {
        ...formField,
        stock: stock,
        description,
        heroImage: heroImg,
        gallery: galleryImage,
        related: productList,
        variants,
        options,
      };

      dispatch(updateProductAsync({ id: product._id, data }));
    }
  };
  useEffect(() => {
    if (updateSuccess) {
      dispatch(reset());
      navigate("/products", { state: { updateSuccess } });
    }
  }, [dispatch, navigate, updateSuccess]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(reset());
      navigate("/products");
    }
  }, [dispatch, deleteSuccess]);

  const [uploading, setUploading] = useState(false);

  const uploadHeroImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setHeroImg(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadGalleryImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setGalleryImage([...galleryImage, data]);

      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const popover = (
    <Popover rootClose className="popover-over-modal" title="Delete payment">
      <section className="p-2 text-center">
        <div className="text-end mb-3">
          <CloseButton
            onClick={(e) => {
              e.preventDefault();

              setShow(false);
            }}
          ></CloseButton>
        </div>
        <h3>Delete this product?</h3>
        <button
          className="bton bton--ghost bton--sm"
          variant="default"
          onClick={(e) => {
            e.preventDefault();
            setShow(false);
          }}
        >
          Cancel
        </button>

        <button
          className="bton bton--sm bton--danger"
          variant="danger"
          onClick={(e) => {
            e.preventDefault();
            dispatch(deleteProductAsync(id));
          }}
        >
          Delete
        </button>
      </section>
    </Popover>
  );

  if (loading) return <LoaderComp />;

  return (
    <>
      <Container>
        <section>
          <h1>Edit Product</h1>
        </section>

        <Row>
          <Col md={4}>
            {" "}
            <section className=" galleryWrapper">
              <section>
                <h2 className="cse">Main Image</h2>
              </section>
              <section>
                <div className="center">
                  <input
                    type="text"
                    placeholder="Please Select Image"
                    style={{ display: "none" }}
                  />
                  <label>
                    <i className="fa fa-cloud-upload"></i>
                    <br />
                    <span>Click to upload Image</span>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={uploadHeroImageHandler}
                    />
                  </label>
                </div>
                {heroImg !== "" && (
                  <figure className="preview">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PREFIX}${heroImg}`}
                      alt=""
                    />
                  </figure>
                )}
                {validate.heroImage && (
                  <small style={{ color: "red" }}>Hero Image is required</small>
                )}
              </section>
              <h2 className="cse mt-5">Gallery Images</h2>
              <div className="center">
                <input
                  type="text"
                  placeholder="Please Select Image"
                  style={{ display: "none" }}
                />
                <label>
                  <i className="fa fa-cloud-upload"></i>
                  <br />
                  <span>Click to upload Image</span>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={uploadGalleryImageHandler}
                  />
                </label>
              </div>
              {galleryImage.length > 0 && (
                <Row>
                  {galleryImage.map((gi, i) => {
                    return (
                      <Col md={6} key={i}>
                        <section className="imageList">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PREFIX}${gi}`}
                            alt=""
                          />
                          <label>
                            <span
                              className="deleteIcon"
                              onClick={(e) => {
                                e.preventDefault();

                                galleryImage = galleryImage.filter(
                                  (d) => d.toString() !== gi.toString()
                                );

                                setGalleryImage(galleryImage);
                              }}
                            >
                              <i className=" fa fa-trash cdngr"></i>
                            </span>
                            <input type="file" style={{ display: "none" }} />
                          </label>
                        </section>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </section>{" "}
          </Col>
          <Col md={8}>
            <section className="descProduct">
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={name}
                        onChange={onChange}
                        placeholder="Enter product name"
                      />
                      {validate.name && (
                        <small style={{ color: "red" }}>Name is required</small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Source</Form.Label>
                      <Form.Control
                        type="text"
                        name="source"
                        value={source}
                        onChange={onChange}
                        placeholder="Product Source"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Order Limit</Form.Label>
                      <Form.Control
                        type="number"
                        name="orderLimit"
                        value={orderLimit}
                        onChange={onChange}
                        min={1}
                        placeholder="set order limit"
                      />
                      {validate.orderLimit && (
                        <small style={{ color: "red" }}>
                          Order Limit must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Category</Form.Label>
                      <p>
                        Current category:{" "}
                        <span className="cwa">{categoryName}</span>{" "}
                      </p>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={async (e) => {
                          setSubCateogryList([]);
                          setSubCateogry2List([]);
                          const catFind = await categories.find(
                            (element) => element._id === e.target.value
                          );

                          setFormField({
                            ...formField,
                            category: e.target.value,
                            subCategory:
                              catFind.subCategory.length > 0
                                ? catFind.subCategory[0].name
                                : "",
                            subCategory2:
                              catFind.subCategory.length === 0
                                ? ""
                                : catFind.subCategory[0].subCategory2.length ===
                                  0
                                ? ""
                                : catFind.subCategory[0].subCategory2[0].name,
                          });
                          setSubCateogryList(catFind.subCategory);

                          catFind.subCategory.length === 0
                            ? setSubCateogry2List([])
                            : catFind.subCategory[0].subCategory2.length === 0
                            ? setSubCateogry2List([])
                            : setSubCateogry2List(
                                catFind.subCategory[0].subCategory2
                              );
                        }}
                      >
                        {category === "" && (
                          <option value="">Select Category</option>
                        )}
                        {categories.map((c, i) => {
                          return (
                            <option key={i} value={c._id}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {validate.category && (
                        <small style={{ color: "red" }}>
                          Category is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Sub Category</Form.Label>
                      {subCateogryName !== "" && (
                        <p>
                          Current Sub name:{" "}
                          <span className="cwa">{subCateogryName}</span>{" "}
                        </p>
                      )}

                      {subCategory.length > 0 ? (
                        <Form.Select
                          aria-label="Default select example"
                          onChange={async (e) => {
                            setSubCateogry2List([]);
                            const findSubCat = await subCategoryList.find(
                              (element) => element._id === e.target.value
                            );
                            setSubCateogry2List(findSubCat.subCategory2);

                            setFormField({
                              ...formField,
                              subCategory: findSubCat.name,
                              subCategory2:
                                findSubCat.subCategory2.length === 0
                                  ? ""
                                  : findSubCat.subCategory2[0].name,
                            });
                          }}
                        >
                          {category === "" && (
                            <option value="">Select Category First</option>
                          )}

                          {subCategoryList &&
                            subCategoryList.map((sc, i) => {
                              return <option value={sc._id}>{sc.name}</option>;
                            })}
                        </Form.Select>
                      ) : (
                        <p className="cwa">no subCategory selected</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Sub Category 2</Form.Label>
                      {subCateogry2Name !== "" && (
                        <span>Current Sub name: {subCateogry2Name}</span>
                      )}
                      {subCategory2List.length > 0 ? (
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setFormField({
                              ...formField,
                              subCategory2: e.target.value,
                            });
                          }}
                        >
                          {subCategory2List.length < 0 && (
                            <option>Select Sub Category First</option>
                          )}
                          {subCategory2List.length > 0 &&
                            subCategory2List.map((ssc, i) => {
                              return (
                                <option value={ssc.name}>{ssc.name}</option>
                              );
                            })}
                        </Form.Select>
                      ) : (
                        <p className="cwa">no subCategory selected</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Price </Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={price}
                        onChange={onChange}
                        placeholder="set price"
                      />
                      {validate.price && (
                        <small style={{ color: "red" }}>
                          Price is required and must greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Discount</Form.Label>
                      <Form.Control
                        type="number"
                        name="discount"
                        value={discount}
                        onChange={onChange}
                        placeholder="set Discount %"
                      />
                      {validate.discount && (
                        <small style={{ color: "red" }}>
                          Discount is required and should be non negative value
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Stock</Form.Label>
                      <Form.Control
                        type="number"
                        name="stock"
                        value={stock}
                        onChange={onChange}
                        placeholder="set stock"
                      />
                      {validate.stock && (
                        <small style={{ color: "red" }}>
                          Stock is required and must be greater than 0
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Description</Form.Label>

                      <ReactQuill
                        value={description}
                        onChange={setDescription}
                      />
                      {validate.description && (
                        <small style={{ color: "red" }}>
                          Description is required
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        onChange={handleFeaturedChange}
                        label="Featured Product"
                        checked={isFeatured === true ? true : false}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        onChange={handleNewArrivalChange}
                        label="New Arrival"
                        checked={newArrival === true ? true : false}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        onChange={handleToDisplayChange}
                        label="Display Product"
                        checked={toDisplay === true ? true : false}
                      />
                      <small className="text-disabled">
                        can be published later
                      </small>
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <h2 className="mt-4">Specifications</h2>
                    <section className="flex-between">
                      <Form.Group className="mb-3 " controlId="formBasicEmail">
                        <Form.Label>Information</Form.Label>
                        <Form.Control
                          type="text"
                          value={informationText}
                          onChange={(e) => setInformationText(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 w-50"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          value={infoDescriptionText}
                          onChange={(e) =>
                            setinfoDescriptionText(e.target.value)
                          }
                        />
                      </Form.Group>
                      <button
                        className="bton bton--md bton--primary mt-4"
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            informationText.trim() !== "" &&
                            infoDescriptionText.trim() !== ""
                          ) {
                            const findIndex = information.findIndex(
                              (ele) =>
                                ele.informationKey.trim() ===
                                informationText.trim()
                            );
                            if (findIndex > -1) {
                              setShowInfoExists(true);
                              setTimeout(() => {
                                setShowInfoExists(false);
                              }, 5000);
                              return;
                            }
                            setFormField({
                              ...formField,
                              information: [
                                ...information,
                                {
                                  informationKey: informationText,
                                  informationValue: infoDescriptionText,
                                },
                              ],
                            });
                            setInformationText("");
                            setinfoDescriptionText("");
                          }
                        }}
                      >
                        Add
                      </button>
                    </section>
                  </Col>
                  {showInfoExists === true && (
                    <p>Information key already in list</p>
                  )}
                  <Col>
                    <section className="Desclist">
                      <ul>
                        {information.length > 0 &&
                          information.map((info, i) => {
                            return (
                              <li className="flex-between Desclist-items">
                                <div>
                                  <strong>{info.informationKey}:</strong>{" "}
                                  <span>{info.informationValue}</span>
                                </div>
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setFormField({
                                      ...formField,
                                      information: information.filter(
                                        (ele) =>
                                          ele.informationKey !==
                                          info.informationKey
                                      ),
                                    });
                                  }}
                                >
                                  <i className="fa fa-trash cda cpntr"></i>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </section>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      className="mb-3 mt-3"
                      controlId="formBasicEmail"
                    >
                      <h2>Related products</h2>
                      <SearchProductsDropdown
                        array={products}
                        placeholder={"Search Products"}
                        setProductList={setProductList}
                        productList={productList}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="12">
                    <ListGroup>
                      {productList.map((ele) => {
                        return (
                          <ListGroup.Item>
                            {" "}
                            <div className="flex-between">
                              <span>{ele.name}</span>
                              <i
                                className="fa fa-trash cda"
                                onClick={(e) => {
                                  e.preventDefault();

                                  setProductList([
                                    ...productList.filter(
                                      (pl) => pl._id !== ele._id
                                    ),
                                  ]);
                                }}
                              ></i>
                            </div>{" "}
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                    <ol></ol>
                  </Col>

                  <hr />

                  <h2>Seo</h2>

                  <Col md={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label> SEO Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="seoDescription"
                        value={seoDescription}
                        onChange={onChange}
                        rows={5}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Type Keywords</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Keyword..."
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                      <button
                        className="bton bton--ghost bton--sm mt-4"
                        onClick={(e) => {
                          e.preventDefault();
                          if (keyword.trim() !== "") {
                            const findIndex = seoKeywords.findIndex(
                              (ele) => ele.trim() === keyword.trim()
                            );
                            if (findIndex > -1) {
                              setShowKeyExists(true);
                              setTimeout(() => {
                                setShowKeyExists(false);
                              }, 5000);
                              return;
                            }
                            setFormField({
                              ...formField,
                              keywords: [...seoKeywords, keyword],
                            });
                          }
                          setKeyword("");
                        }}
                      >
                        {" "}
                        Add
                      </button>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <div>Keywords List</div>
                    {showKeyExists === true && <p>Keyword already exists</p>}
                    <section className="tagsHolder">
                      {seoKeywords.length > 0 ? (
                        seoKeywords.map((keyword, i) => {
                          return (
                            <div className="tagPills" pill bg="secondary">
                              {keyword}{" "}
                              <CloseButton
                                onClick={(e) => {
                                  e.preventDefault();

                                  setFormField({
                                    ...formField,
                                    keywords: seoKeywords.filter(
                                      (ele) => ele.trim() !== keyword.trim()
                                    ),
                                  });
                                }}
                              ></CloseButton>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-muted">no keywords added !!!</div>
                      )}
                    </section>
                  </Col>

                  <Col md={12}>
                    <Variant
                      variants={variants}
                      setVariants={setVariants}
                      options={options}
                      setOptions={setOptions}
                      price={formField.price}
                      images={[
                        ...allImages,
                        formField.heroImage,
                        ...galleryImage,
                      ]}
                      optionsError={optionsError}
                    />
                    {variantsError ? (
                      <small style={{ color: "red" }}>{variantsError}</small>
                    ) : null}
                  </Col>
                </Row>
                <div>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    show={show}
                    overlay={popover}
                  >
                    <button
                      className="bton bton--nacked bton--md cwa"
                      style={{ color: "red", textDecoration: "underline" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(true);
                      }}
                    >
                      Delete Product
                    </button>
                  </OverlayTrigger>
                </div>

                <button
                  className="bton bton--primary bton--sm mt-4"
                  onClick={(e) => submitForm(e)}
                >
                  {" "}
                  Update Product
                </button>

                <Link to="/products">
                  <button className="bton bton--sm bton--ghost--danger ms-2">
                    discard changes
                  </button>
                </Link>
              </Form>
            </section>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProduct;
