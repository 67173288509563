import React from "react";

const DeleteSubVariant = ({ type, name, showBox, deleteHandler }) => {
  const handleDelete = () => {
    deleteHandler(type, name);
    showBox(false);
  };
  return (
    <div className="variants-popup">
      <div className="variants-popup_overlay" onClick={() => showBox(false)} />
      <div className="variants-delete">
        <div className="variants-delete_header">
          <button onClick={() => showBox(false)}>
            <i className="fa fa-close"></i>
          </button>
        </div>
        <div className="variants-delete_main">
          <span className="variants-delete_label">
            Are you sure you want to delete {name} {type}?
          </span>
          <div className="variants-delete_btns">
            <button
              type="button"
              className="bton bton--sm confirm-btn"
              onClick={handleDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="bton bton--sm cancel-btn"
              onClick={() => showBox(false)}
            >
              Keep
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteSubVariant;
