import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCategoriesAsync = createAsyncThunk(
  'category/getCategoriesAsync',
  async (_, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get('/api/categories/');
    if (response.status === 200) {
      const categories = response.data.categories;
      return { categories };
    }
  }
);

export const createCategoryAsync = createAsyncThunk(
  'category/createCategoryAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.post('/api/categories/', data, config);

    if (response.status === 201) {
      const category = response.data;
      return { category };
    }
  }
);

export const updateCategoryAsync = createAsyncThunk(
  'category/updateCategoryAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/categories/${data.id}`,
      data,
      config
    );

    if (response.status === 200) {
      const category = response.data;
      return { category };
    }
  }
);

export const toggleRemovedStatusAsync = createAsyncThunk(
  'category/toggleRemovedStatusAsync',
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/categories/toggleremovedstatus/${id}`,
      {},
      config
    );
    if (response.status === 200) {
      const togR = response.data;
      return { togR };
    }
  }
);

export const createSubCategoryAsync = createAsyncThunk(
  'cateogry/createSubCategoryAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const { id, name } = data;
    const response = await axios.put(
      `/api/categories/${id}/sub-category`,
      {
        name: name,
      },
      config
    );
    if (response.status === 200) {
      const subCategory = response.data.subCategory;
      return { subCategory };
    }
  }
);

export const createSubCategory2Async = createAsyncThunk(
  'category/createSubCategory2Async',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const { id, subid, name } = data;
    const response = await axios.put(
      `/api/categories/${id}/sub-category/${subid}/`,
      {
        name,
      },
      config
    );
    if (response.status === 200) {
      const subCategory2 = response.data.subCategory2;
      return { subCategory2 };
    }
  }
);

export const updateSubCategoryAsync = createAsyncThunk(
  'category/updateSubCategoryAsync',
  async (form, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const { id, subId, data } = form;
    const response = await axios.put(
      `/api/categories/${id}/sub-category/${subId}/update`,
      data,
      config
    );
    if (response.status === 200) {
      const updatedProduct = response.data;
      return { updatedProduct };
    }
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    category: [],
    loading: false,
    creatingSub: false,
    creatingSsub: false,
    updatingDone: false,
    catUpdateDone: false,
    isSuccess: false,
    toggleSuccess: false,
  },
  reducers: {
    reset: (state) => {
      state.creatingSub = false;
      state.creatingSsub = false;
      state.updatingDone = false;
      state.catUpdateDone = false;
    },
    resetToggle: (state) => {
      state.toggleSuccess = false;
    },
  },
  extraReducers: {
    [getCategoriesAsync.pending]: (state, action) => {
      state.loading = true;
      state.isError = false;
    },
    [getCategoriesAsync.fulfilled]: (state, action) => {
      state.category = action.payload.categories;
      state.loading = false;
      state.updatingDone = false;
      state.creatingSub = false;
      state.creatingSsub = false;
    },

    [createSubCategoryAsync.fulfilled]: (state, action) => {
      state.creatingSub = true;
    },

    [createSubCategory2Async.fulfilled]: (state, action) => {
      state.creatingSsub = true;
    },

    [updateSubCategoryAsync.fulfilled]: (state, action) => {
      state.updatingDone = true;
    },

    [createCategoryAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createCategoryAsync.fulfilled]: (state, action) => {
      state.category = [...state.category, action.payload.category];
      state.loading = false;
    },

    [updateCategoryAsync.fulfilled]: (state, action) => {
      state.catUpdateDone = true;
    },
    [toggleRemovedStatusAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
  },
});

export const { reset, resetToggle } = categorySlice.actions;

export default categorySlice.reducer;
