import React, { useState } from "react";
import { Form } from "react-bootstrap";

const AddSubVariant = ({ name, onAdd }) => {
  const [value, setValue] = useState("");

  const handleAdd = () => {
    onAdd(name, value);
    setValue("");
  };

  const handleAddEnter = (e) => {
    if (e.key === "Enter") {
      handleAdd();
    }
  };

  return (
    <div className="variant-subtype mb-2">
      <Form.Control
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleAddEnter}
        placeholder="Eg: Red"
        className="variant-subtype_input"
      />
      <button type="button" className="variant-subtype_btn" onClick={handleAdd}>
        <i className="fa fa-plus" />
      </button>
    </div>
  );
};

export default AddSubVariant;
