import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import UploadVariantImage from "./UploadVariantImage";

const VariantProductItem = ({
  data,
  handleChange,
  type1,
  type2,
  images,
  uploaded,
  setUploaded,
  imageHandler,
}) => {
  const [stock, setStock] = useState(data.stock);
  const [price, setPrice] = useState(data.price);
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    handleChange({
      subType1: data[type1],
      subType2: data[type2],
      stock: parseInt(stock),
      price: parseInt(price),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, stock]);

  return (
    <tr className="variant-products_table_row">
      <td className="variant-products_table_td">
        <button
          type="button"
          onClick={() => setShowUpload(true)}
          className="variant-products_table_image"
        >
          {data.images.length > 0 ? (
            <img
              src={`${process.env.REACT_APP_IMAGE_PREFIX}${data.images[0]}`}
              alt=""
            />
          ) : (
            <i className="fa fa-image"></i>
          )}
        </button>
      </td>
      <td className="variant-products_table_td">
        <span className="variant-products_table_name">
          {data[type1]}
          {data[type2] ? "/" + data[type2] : null}
        </span>
      </td>
      <td className="variant-products_table_td">
        <div className="variant-products_table_input">
          <Form.Control
            type="number"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
            placeholder="Stock"
            className="variants-subtype_input"
          />
        </div>
      </td>
      <td className="variant-products_table_td">
        <div className="variant-products_table_input">
          <Form.Control
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Price"
            className="variants-subtype_input"
          />
        </div>
      </td>
      {showUpload ? (
        <UploadVariantImage
          showBox={setShowUpload}
          selectedImages={data.images}
          images={images}
          uploaded={uploaded}
          setUploaded={setUploaded}
          imageHandler={imageHandler}
          subType1={data[type1]}
          subType2={data[type2]}
        />
      ) : null}
    </tr>
  );
};

export default VariantProductItem;
