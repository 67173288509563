import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPromoCodesAsync = createAsyncThunk(
  'promo/getPromoCodesAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get(`/api/promo`, config);
    if (response.status === 200) {
      return response.data;
    }
  }
);

export const addPromoAsync = createAsyncThunk(
  'promos/addPromoAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const response = await axios.post('/api/promo', data, config);
    if (response.status === 201) {
      const promo = response.data;
      return { promo };
    }
  }
);

export const togglePromoCodeStatusAsync = createAsyncThunk(
  'promo/togglePromoCodeStatusAsync',
  async (id, thunkAPI) => {
    const { user } = thunkAPI.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(`/api/promo/status/${id}`, {}, config);
    if (response.status === 200) {
      return 'Status Changed';
    }
  }
);

export const deletePromoCodeAsync = createAsyncThunk(
  'promo/deletePromoCodeAsync',
  async (id, thunkAPI) => {
    const { user } = thunkAPI.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.delete(`/api/promo/${id}`, config);
    if (response.status === 200) {
      return id;
    }
  }
);

const promoSlice = createSlice({
  name: 'promo',
  initialState: {
    promos: [],
    loading: false,
    toggleSuccess: false,
  },
  reducers: {
    reset: (state) => {
      state.toggleSuccess = false;
    },
  },
  extraReducers: {
    [getPromoCodesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getPromoCodesAsync.fulfilled]: (state, action) => {
      state.promos = action.payload;
      state.loading = false;
    },

    [addPromoAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addPromoAsync.fulfilled]: (state, action) => {
      state.promos = [...state.promos, action.payload.promo];
      state.loading = false;
    },
    [togglePromoCodeStatusAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
    [deletePromoCodeAsync.fulfilled]: (state, action) => {
      state.promos = state.promos.filter((pr) => pr._id !== action.payload);
    },
  },
});

export const { reset } = promoSlice.actions;

export default promoSlice.reducer;
