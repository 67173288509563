import React, { useState } from "react";
import { Form } from "react-bootstrap";

const UpdateSubVariant = ({
  type,
  oldValue,
  updateHandler,
  showBox,
  values,
}) => {
  const [value, setValue] = useState(oldValue);
  const [valueError, setValueError] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    if (
      e.target.value !== oldValue &&
      values.find(
        (entry) => entry.toLowerCase() === e.target.value.toLowerCase()
      )
    ) {
      setValueError("Already Exists!");
    } else {
      setValueError("");
    }
  };

  const handleUpdate = () => {
    let err = false;
    if (!value.trim() || !value) {
      setValueError("Required");
      err = true;
    }
    if (!err) {
      if (!valueError && value !== oldValue) {
        updateHandler(type, oldValue, value);
        showBox(false);
      } else if (!valueError) {
        showBox(false);
      }
    }
  };

  const handleAddEnter = (e) => {
    if (e.key === "Enter") {
      handleUpdate();
    }
  };

  return (
    <div className="variants-popup">
      <div className="variants-popup_overlay" onClick={() => showBox(false)} />
      <div className="variants-update">
        <div className="variants-update_header">
          <span>Edit</span>
          <button onClick={() => showBox(false)}>
            <i className="fa fa-close"></i>
          </button>
        </div>
        <div className="variants-update_main">
          <div>
            <div className="mb-3">
              <span className="variants-update_label">{type} Type</span>
              <Form.Control
                type="text"
                placeholder="Eg: Color"
                value={value}
                onChange={handleChange}
                onKeyDown={handleAddEnter}
              />
              {valueError ? <span>{valueError}</span> : null}
            </div>
            <button
              type="button"
              className="bton bton--sm bton--full bton--primary"
              onClick={handleUpdate}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSubVariant;
