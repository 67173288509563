import React, { useState } from 'react';
import { useEffect } from 'react';

function SearchProductsDropdown({
  array,
  placeholder,
  setProductList,
  productList,
}) {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [searchTerm]);
  return (
    <section>
      <input
        style={{ width: '100%', padding: '.5rem' }}
        type='search'
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {show && (
        <div
          style={{
            position: 'absolute',
            width: '525px',
            background: '#ffffff',
            border: '1px solid #f4f4f2',
            padding: '10px ',
            zIndex: '9999',
            boxShadow: '1px 2px 6px #ababab',
            maxHeight: '500px',
            overflowY: 'auto',
          }}
        >
          {array &&
            array
              .filter((i) => {
                return i.name.toLowerCase().includes(searchTerm.toLowerCase());
              })
              .map((i, index) => {
                return (
                  <p
                    className='relatedDropdown'
                    key={i._id}
                    onClick={() => {
                      setProductList([...productList, i]);
                      setSearchTerm('');
                    }}
                  >
                    {i.name}
                  </p>
                );
              })}
        </div>
      )}
    </section>
  );
}
export default SearchProductsDropdown;
