import React, { useState } from "react";
import AddSubVariant from "./AddSubVariant";
import { Col } from "react-bootstrap";
import UpdateVariant from "./UpdateVariant";
import SubVariantItem from "./SubVariantItem";

const VariantItem = ({
  variant,
  options,
  addHandler,
  updateVariantHandler,
  deleteVariantHandler,
  updateSubVariantHandler,
  deleteSubVariantHandler,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <Col md={6}>
      <div className="variant">
        <div className="variant-header">
          <span>{variant.name}</span>
          <button
            type="button"
            className="edit-btn"
            onClick={() => setShowEdit(true)}
          >
            Edit
          </button>
        </div>
        {showEdit ? (
          <UpdateVariant
            name={variant.name}
            options={options}
            deleteHandler={deleteVariantHandler}
            updateHandler={updateVariantHandler}
            showBox={setShowEdit}
          />
        ) : null}
        <AddSubVariant name={variant.name} onAdd={addHandler} />
        <div className="variant-list">
          {variant.values.map((item, key) => {
            return (
              <SubVariantItem
                key={key}
                name={item}
                type={variant.name}
                values={variant.values}
                updateSubVariantHandler={updateSubVariantHandler}
                deleteSubVariantHandler={deleteSubVariantHandler}
              />
            );
          })}
        </div>
      </div>
    </Col>
  );
};

export default VariantItem;
