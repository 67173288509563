import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { rejectReviewAsync } from "../../redux/reviewSlice";
import { LuLoader2 } from "react-icons/lu";

const ReviewRejectCard = ({ showModal, id }) => {
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleReject = async (e) => {
    e.preventDefault();

    if (!reason) {
      setReasonError("Required!");
    } else {
      try {
        setLoading(true);
        await dispatch(rejectReviewAsync({ reason, id })).unwrap();
        showModal(false);
      } catch (err) {
        setError(
          (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString()
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleReject} className="reviews-reject">
      <div>
        <textarea
          className="reviews-reject_input"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Reason for rejection..."
        />
        {reasonError && (
          <span className="reviews-reject_error">{reasonError}</span>
        )}
      </div>
      {error && (
        <span
          className="text-center"
          style={{
            color: "red",
            fontSize: "14px",
          }}
        >
          {error}
        </span>
      )}
      <div className="reviews-reject_btns">
        <button
          type="button"
          className="reviews-reject_btns--cancel"
          onClick={() => showModal(false)}
        >
          Cancel
        </button>
        <button type="submit" className="reviews-reject_btns--submit">
          {loading ? <LuLoader2 className="rmodal_spin" /> : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default ReviewRejectCard;
