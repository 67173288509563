import React from "react";
import VariantItem from "./VariantItem";
import AddVariant from "./AddVariant";
import { Row } from "react-bootstrap";
import VariantProducts from "./VariantProducts";

const Variant = ({
  options,
  setOptions,
  variants,
  setVariants,
  price,
  images,
  optionsError,
}) => {
  const addOptionHandler = (value) => {
    if (
      value &&
      value.trim() &&
      options.length < 2 &&
      !options.find((e) => e.name.toLowerCase() === value.toLowerCase())
    ) {
      setOptions([...options, { name: value, values: [] }]);
    }
  };

  const addSubOptionHandler = (name, value) => {
    if (
      value &&
      value.trim() &&
      !options.find(
        (e) =>
          e.name === name &&
          e.values.find((i) => i.toLowerCase() === value.toLowerCase())
      )
    ) {
      setOptions((prev) =>
        prev.map((entry) =>
          entry.name === name
            ? { name: name, values: [...entry.values, value] }
            : entry
        )
      );
      addVariantHandler(name, value);
    }
  };

  const addVariantHandler = (type, value) => {
    const anotherArr = options.find((e) => e.name !== type);
    const tempArr = [];

    if (anotherArr && anotherArr.values.length > 0) {
      setVariants((prev) => prev.filter((e) => e[type] && e[anotherArr.name]));
      for (const entry of anotherArr.values) {
        const t = {
          [type]: value,
          [anotherArr.name]: entry,
          stock: 0,
          price: price,
          images: [],
        };
        tempArr.push(t);
      }
    } else {
      const t = {
        [type]: value,
        stock: 0,
        price: price,
        images: [],
      };
      tempArr.push(t);
    }
    setVariants((prev) => [...prev, ...tempArr]);
  };

  const updateOptionHandler = (oldValue, newValue) => {
    setOptions((prev) =>
      prev.map((entry) =>
        entry.name === oldValue ? { ...entry, name: newValue } : entry
      )
    );
    updateVariantKeyHandler(oldValue, newValue);
  };

  const updateVariantKeyHandler = (oldValue, newValue) => {
    setVariants((prev) => {
      const updated = prev.map((entry) => {
        const { [oldValue]: value, ...rest } = entry;
        const updatedObj = { [newValue]: value, ...rest };
        return updatedObj;
      });
      return updated;
    });
  };

  const updateSubVariantHandler = (type, oldValue, newValue) => {
    setOptions((prev) =>
      prev.map((entry) =>
        entry.name === type
          ? {
              ...entry,
              values: entry.values.map((e) => (e === oldValue ? newValue : e)),
            }
          : entry
      )
    );
    updateVariantHandler(type, oldValue, newValue);
  };

  const updateVariantHandler = (type, oldValue, newValue) => {
    setVariants((prev) =>
      prev.map((entry) =>
        entry[type] === oldValue
          ? {
              ...entry,
              [type]: newValue,
            }
          : entry
      )
    );
  };

  const deleteOptionHandler = (value) => {
    setOptions((prev) => prev.filter((entry) => entry.name !== value));
    deleteVariantHandler(value);
  };

  const deleteVariantHandler = (type) => {
    const anotherArr = options.find((e) => e.name !== type);

    if (!anotherArr) {
      setVariants([]);
    } else {
      setVariants((prev) => {
        const deleted = prev.map((entry) => {
          delete entry[type];
          return entry;
        });

        return deleted.filter(
          (obj, i) =>
            i ===
            deleted.findIndex(
              (o) => obj[anotherArr.name] === o[anotherArr.name]
            )
        );
      });
    }
  };

  const deleteSubOptionHandler = (type, value) => {
    setOptions((prev) =>
      prev.map((entry) =>
        entry.name === type
          ? { ...entry, values: entry.values.filter((e) => e !== value) }
          : entry
      )
    );
    deleteSubVariantHandler(type, value);
  };

  const deleteSubVariantHandler = (type, value) => {
    setVariants((prev) => prev.filter((entry) => entry[type] !== value));
  };

  return (
    <div className="variants mt-4">
      <h2>Variants</h2>
      <span className="variants_sub">You can add only upto 2 groupings</span>
      <AddVariant onAdd={addOptionHandler} />
      <Row>
        {options.map((variant, key) => {
          return (
            <VariantItem
              key={key}
              variant={variant}
              options={options}
              addHandler={addSubOptionHandler}
              updateVariantHandler={updateOptionHandler}
              deleteVariantHandler={deleteOptionHandler}
              updateSubVariantHandler={updateSubVariantHandler}
              deleteSubVariantHandler={deleteSubOptionHandler}
            />
          );
        })}
        {optionsError ? (
          <small style={{ color: "red" }}>{optionsError}</small>
        ) : null}
      </Row>
      <VariantProducts
        variants={variants}
        setVariants={setVariants}
        type1={options.length > 0 ? options[0].name : null}
        type2={options.length > 1 ? options[1].name : null}
        images={images}
      />
    </div>
  );
};

export default Variant;
